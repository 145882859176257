<template>
  <div :class="[labeltype == 'none' ? 'form-entry-container-none' : 'form-entry-container']" :id="component">
    <div v-if="labeltype == 'none'">
      {{ title }}
    </div>
    <div class="form-entry-label" :style="labelstyle" v-else-if="labeltype != 'none'">
      {{ title }}
    </div>
    <div class="form-entry-input">
      <div v-if="type == 'picoseditor'">
        <div style="float: left;">
          <div style="font-size: 1.1em; font-weight: bold;">Inclusion Criteria</div>
          <kendo-editor :ref="'InclusionEditor'" :value="includevalue" :content-style="contentStyle" :tools="tools"></kendo-editor>
        </div>
        <div style="float: left;">
          <div style="font-size: 1.1em; font-weight: bold;">Exclusion Criteria</div>
          <kendo-editor :ref="'ExclusionEditor'" :value="excludevalue" :content-style="contentStyle" :tools="tools"></kendo-editor>
        </div>
        <div style="float: left;">
          <kendo-button v-if="remove == 'true'">Remove Criteria</kendo-button>
        </div>
      </div>
      <div v-if="type == 'picostextarea'">
        <div style="float: left; margin-right: 10px;">
          <div style="font-size: 1.1em; font-weight: bold;">Inclusion Criteria</div>
          <textarea :rows="9" style="min-width:600px; max-width:100%; min-height:50px; height:100%; width:100%;" v-model="includevalue"></textarea>
        </div>
        <div style="float: left;">
          <div style="font-size: 1.1em; font-weight: bold;">Exclusion Criteria</div>
          <textarea :rows="9" style="min-width:600px; max-width:100%; min-height:50px; height:100%; width:100%;" v-model="excludevalue"></textarea>
        </div>
        <div style="float: left;">
          <kendo-button v-if="remove == 'true'">Remove Criteria</kendo-button>
        </div>
      </div>
      <div v-if="type == 'formKeywordReadOnly'">
        <kendo-datasource
          :ref="'FormKeywordDataSource'"
          :data="formKeywordDataSource.data"
          :filter="formKeywordFilter">
        </kendo-datasource>
        <kendo-grid
              :ref="'formKeywordReadOnlyGrid'"
              :data-source-ref="'FormKeywordDataSource'"
              style="width: 500px;">
              <kendo-grid-column field="FormKeywordName" title="Keyword"></kendo-grid-column>
        </kendo-grid>
      </div>
      <div v-if="type == 'formKeywordEdit'">
        <kendo-datasource
          :ref="'FormKeywordDataSource'"
          :data="formKeywordDataSource.data"
          :filter="formKeywordFilter"
          style="width: 500px;">
        </kendo-datasource>
        <button class="k-button">Add</button>
        <kendo-grid
              :ref="'formKeywordEditGrid'"
              :data-source-ref="'FormKeywordDataSource'">
              <kendo-grid-column field="FormKeywordName" title="Keyword"></kendo-grid-column>
              <kendo-grid-column template="<button class='k-button'>Edit</button><button class='k-button'>Remove</button>"></kendo-grid-column>
        </kendo-grid>
      </div>
      <div v-if="type == 'formTagReadOnly'">
        <kendo-datasource
          :ref="'FormTagDataSource'"
          :data="formTagDataSource.data"
          :filter="formTagFilter">
        </kendo-datasource>
        <kendo-grid
              :ref="'formTagReadOnlyGrid'"
              :data-source-ref="'FormTagDataSource'"
              style="width: 500px;">
              <kendo-grid-column field="FormTagName" title="Tag"></kendo-grid-column>
        </kendo-grid>
      </div>
      <div v-if="type == 'formTagAdd'">
        <kendo-datasource
          :ref="'FormTagDataSource'"
          :data="formTagDataSource.data"
          :filter="formTagFilter"
          style="width: 500px;">
        </kendo-datasource>
        <button class="k-button">Add</button>
        <kendo-grid
              :ref="'formTagAddGrid'"
              :data-source-ref="'FormTagDataSource'">
              <kendo-grid-column field="FormTagName" title="Tag"></kendo-grid-column>
              <kendo-grid-column template="<button class='k-button'>Edit</button><button class='k-button'>Remove</button>"></kendo-grid-column>
        </kendo-grid>
      </div>
      <div v-if="type == 'formTagEdit'">
        <kendo-datasource
          :ref="'FormTagDataSource'"
          :data="formTagDataSource.data"
          :filter="formTagFilter"
          style="width: 500px;">
        </kendo-datasource>
        <button class="k-button">Add</button>
        <kendo-grid
              :ref="'formTagEditGrid'"
              :data-source-ref="'FormTagDataSource'">
              <kendo-grid-column field="FormTagName" title="Tag"></kendo-grid-column>
              <kendo-grid-column template="<button class='k-button'>Edit</button><button class='k-button'>Remove</button>"></kendo-grid-column>
        </kendo-grid>
      </div>
      <div
        v-if="type == 'label'">{{ text }}</div>
      <input
        type="text"
        v-if="type == 'text' && readonly != 'true'"
        :value="value"
        :size="size"
        :style="inputstyle" :class="[readonly? 'text-disabled': '']">
        <div v-if="type == 'text' && readonly == 'true'">{{ text }}</div>
      <textarea
        v-if="type == 'textarea' && readonly != 'true'"
        :cols="cols"
        :value="value"
        :rows="rows" :class="[readonly? 'text-disabled': '']">
      </textarea>
      <div v-if="type == 'textarea' && readonly == 'true'">{{ text }}</div>
      <input
        type="checkbox"
        v-if="type == 'checkbox'"
        :checked="checked">
      <kendo-dropdownlist
        v-if="type == 'dropdown'"
        :data-source="datasource"
        :data-text-field="textfield"
        :data-value-field="valuefield"
        :value="value"
        :disabled='readonly'
        :option-label="'Select ' + title + '...'"
        :auto-width="autoWidth"
        style="width: calc(100% - 250px);"
        :change="onChange">
      </kendo-dropdownlist>
      <kendo-treelist
        v-if="type == 'treelist'"
        :data-source="datasource"
        selectable=true
        :columns="datasource.columns"
        style="width: calc(100% - 250px);">
      </kendo-treelist>
      <kendo-dropdowntree
        v-if="type == 'dropdowntree'"
        :data-source-ref="'treeListDataSourceRef'"
        data-text-field="['ParentFormName', 'ChildFormName']"
        data-value-field="['ParentFormName', 'ChildFormName']"
        :value="value"
        :option-label="'Select ' + title + '...'"
        :change="onChange">
      </kendo-dropdowntree>
      <kendo-multiselect
        v-if="type == 'multiselect'"
        :data-source="datasource"
        :data-text-field="textfield"
        :data-value-field="valuefield">
      </kendo-multiselect>
      <div class="form-entry-input" v-if="type == 'radioYesNo'">
        <input :ref="'radioYes'" type="radio" :disabled='readonly' :name="name" @click="onRadioYes">
        <label for="single">Yes</label>
        <input :ref="'radioNo'" type="radio" :disabled='readonly' :name="name" @click="onRadioNo">
        <label for="single">No</label>
      </div>
      <div v-if="type == 'checkBoxAndColor'" style="display: flex; flex-direction: row;">
        <div><input type="checkbox" @change="onShowColorPicker"></div>
        <div>Associated Color:</div>
        <div>
          <kendo-colorpicker
            :enabled="showColorPicker"
            :palette="paletteArray">
          </kendo-colorpicker>
        </div>
        <div>{{ note }}</div>
      </div>
      <div v-if="type == 'surveyViewer'" style="display: flex; flex-direction: row;">
        <div id="SurveyViewerContainerV1" style="width: calc(100% - 120px);"></div>
      </div>
      <div v-if="type == 'surveyEditor'" style="display: flex; flex-direction: row;">
        <div id="SurveyEditorContainerV1" style="width: calc(100% - 120px);"></div>
      </div>
      <div v-if="type == 'surveyViewer'" style="display: flex; flex-direction: row;">
        <div id="SurveyViewerContainerV2" style="width: calc(100% - 120px);"></div>
      </div>
      <div v-if="type == 'surveyEditor'" style="display: flex; flex-direction: row;">
        <div id="SurveyEditorContainerV2" style="width: calc(100% - 120px);"></div>
      </div>
      <kendo-upload
        v-if="type == 'file'"
        :ref="'uploadFile'"
        :localization-select="'Select File'">
      </kendo-upload>
      <kendo-upload
        v-if="type == 'downloadFolder'"
        :ref="'downloadFolder'"
        :localization-select="'Select Folder'"
        :directory="true">
      </kendo-upload>
    </div>
  </div>
</template>

<script>

import PicosItemDataSource from '@/assets/data/PicosItem.json'
import FormKeywordDataSource from '@/assets/data/FormKeyword.json'
import FormTagDataSource from '@/assets/data/FormTag.json'
import SurveyTemplateCompleteEditor from '@/assets/data/SurveyTemplateCompleteEditor.json'

export default {
  props: ['name', 'title', 'type', 'size', 'datasource', 'textfield', 'valuefield', 'component', 'value', 'text', 'cols', 'rows', 'labelstyle', 'labeltype', 'inputstyle', 'palettearray', 'filter', 'note', 'autowidth', 'readonly', 'add', 'remove', 'includevalue', 'excludevalue', 'checked'],
  data () {
    return {
      paletteArray: ['#BFD7EA', '#9B7EDE', '#832161', '#52050A', '#76E7CD', '#FF6B35', '#0ABAB5', '#62A8AC', '#F4D6CC', '#899D78', '#F0CFC3', '#F0BCD4', '#F4B860', '#FFB86F', '#E0777D', '#E0CA3C'],
      inclusionEditorWidget: {},
      excludeEditorWidget: {},
      autoWidth: true,
      showColorPicker: false,
      picosItemDataSource: PicosItemDataSource,
      formKeywordDataSource: FormKeywordDataSource,
      formTagDataSource: FormTagDataSource,
      surveyTemplateCompleteEditor: SurveyTemplateCompleteEditor,
      picosFilter: { field: 'PicosItemType', operator: 'eq', value: this.filter },
      formKeywordFilter: { field: 'KeywordName', operator: 'eq', value: this.filter },
      formTagFilter: { field: 'TagName', operator: 'eq', value: this.filter },
      tools: [],
      contentStyle: '{ color: #333; }'
    }
  },
  methods: {
    onChange: function (e) {
      this.$emit('change', e)
    },
    onRadioYes: function () {
      this.$refs.radioNo.checked = false
    },
    onRadioNo: function () {
      this.$refs.radioYes.checked = false
    },
    onDragStart: function () {
      alert()
    },
    onShowColorPicker: function (e) {
      console.log(e.srcElement.checked)
      this.showColorPicker = e.srcElement.checked
    },
    onExport: function () {
      console.log(this.inclusionEditorWidget.value())
    }
  },
  mounted: function () {
    console.log(1)
    this.inclusionEditorWidget = this.$refs.InclusionEditor.kendoWidget()
    console.log(2)
    this.exclusionEditorWidget = this.$refs.ExclusionEditor.kendoWidget()
  }
}
</script>

<style>

.text-disabled {
  background-color: lightgrey;
  border: solid grey 1px;
  color: grey;
  cursor: not-allowed;
}

.text-disabled .k-dropdown .k-dropdown-wrap {
  background-color: lightgrey;
  border: solid grey 1px;
  color: grey;
  cursor: not-allowed;
}

#SurveyViewerContainerV2 {
  height: 100vh;
  width: 100vw;
}
#SurveyEditorContainerV2 {
  height: 100vh;
  width: 100vw;
}

table.k-editor {
  width: 600px;
  margin: 5px 5px 5px 5px;
}

table.k-widget.k-editor {
  width: 600px;
}

iframe.k-content {
  width: 600px;
  height: 400px;
}

.k-toolbar {
  width: 600px;
  height: 0px;
  padding: 0px;
}

.k-dropdown {
  font-size: 1.1em;
}
</style>
